import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    currentUrl: { type: String, default: '/' },
    querySelector: { type: String, default: 'a.nav-link' },
    activeClass: { type: String, default: 'active' },
  }

  connect() {
    this.setFirstLinkActive()
  }

  isActiveLink(url, urlValue) {
    return url == urlValue
  }

  setFirstLinkActive() {
    const tabNavLinks = this.element.querySelectorAll(this.querySelectorValue)
    tabNavLinks[0].classList.add(...this.activeClassValue.split(' '))
  }

  setActiveLink(currentUrlValue) {
    const tabNavLinks = this.element.querySelectorAll(this.querySelectorValue)

    for (let i = 0; i < tabNavLinks.length; i++) {
      tabNavLinks[i].classList.remove(...this.activeClassValue.split(' '))

      if (this.isActiveLink(tabNavLinks[i].href, currentUrlValue)) {
        tabNavLinks[i].classList.add(...this.activeClassValue.split(' '))
      }
    }
  }

  updateActiveLink(event) {
    const currentUrl = event.currentTarget.href
    const currentUrlDetail = (event.detail || {}).href
    this.setActiveLink(currentUrl || currentUrlDetail)
  }
}
